/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-22 08:36:06
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-04-07 14:19:53
 */
import React from "react"
// import { Link } from "gatsby"

import { rhythm } from "../utils/typography"
import Header from './Header/index'

const Layout = ({ children }) => {
  return (
    <div>
      <Header/>
      <div
        className="blog-page"
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: 900,//rhythm(34),
          padding: `${rhythm(.5)} ${rhythm(3 / 4)}`,
        }}
      >
        
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.vis27.com">彩色说</a>
        </footer>
      </div>
    </div>
  )
}

export default Layout
